/* Variables */

// Typefaces
$primary-type: georgia, serif;
$secondary-type: 'league-gothic', 'league gothic', 'arial narrow', sans-serif;
$tertiary-type: 'm-1m', 'm+ 1m', monospace;

// Responsiveness
$smaller-screen: "(max-width: 940px)";
$smaller-screen-body-margin: 1em;

// Fonts
@font-face {
	font-family: "league-gothic";
	src: url("/assets/fonts/LeagueGothic-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "m-1m";
	src: url("/assets/fonts/MplusCodeLatin50-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "m-1m";
	src: url("/assets/fonts/MplusCodeLatin50-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

:root {
	--primary-color: hsl(0, 0%, 27%);
	--primary-color-lighter: hsl(0, 0%, 50%);

	--secondary-color: hsl(199, 98%, 50%);
	--secondary-color-lighter: hsl(199, 98%, 60%);
	--secondary-color-dim: hsla(199, 98%, 50%, 0.05);

	--accent-color: hsl(154, 50%, 50%);
	--accent-color-lighter: hsl(154, 50%, 50%);
	--accent-color-dim: hsla(154, 50%, 50%, 0.1);

	--offset-color: #fff;
	--comments-stripe-color: hsl(0, 0%, 98%);

	--success-color: hsl(120, 50%, 90%);
	--error-color: hsl(0, 50%, 90%);
}

@media (prefers-color-scheme: dark) {
	:root {
		--primary-color: hsl(0, 0%, 87%);
		--primary-color-lighter: hsl(0, 0%, 60%);

		--secondary-color: hsl(202, 78%, 66%);
		--secondary-color-lighter: hsl(202, 78%, 75%);
		--secondary-color-dim: hsla(202, 78%, 75%, 0.05);

		--accent-color: hsl(0, 61%, 61%);
		--accent-color-lighter: hsl(0, 61%, 61%);
		--accent-color-dim: hsla(0, 61%, 61%, 0.1);

		--offset-color: #222;
		--comments-stripe-color: hsl(0, 0%, 18%);

		--success-color: hsl(120, 50%, 20%);
		--error-color: hsl(0, 50%, 20%);
	}

	div.zoom-overlay {
		background: var(--offset-color);
	}
}

/* General */

body {
	background-color: var(--offset-color);
	color: var(--primary-color);
	font-family: $primary-type;
	font-size: 18px;
	margin: 3em 0 3em 15vw;
	width: 600px;
}

@media #{$smaller-screen} {
	body {
		margin: $smaller-screen-body-margin;
		width: auto;
	}
}

h1, h2, h3 {
	font-family: $secondary-type;
	font-weight: normal;
	text-transform: uppercase;
}

h4, h5, h6 {
	font-family: $tertiary-type;
	font-weight: normal;
	text-transform: lowercase;
}

@media #{$smaller-screen} {
	h1 {
		margin-bottom: 0;
	}
}

a:link {
	color: var(--secondary-color);
}

a:visited {
	color: var(--secondary-color-lighter);
}

a:hover {
	background-color: var(--secondary-color);
	color: var(--offset-color);
	text-decoration: none;
}

img {
	background-color: var(--offset-color); /* for non-rectangular image links on hover */
}

@media #{$smaller-screen} {
	img {
		margin-left: -$smaller-screen-body-margin;
		width: 100vw;
	}
}

.box {
	padding: 0.5em;
	margin-bottom: 2rem;
}

.box-info {
	background-color: var(--secondary-color-dim);
	border-top: 1px dotted var(--secondary-color);
}

.box-success {
	background-color: var(--success-color);
}

.box-error {
	background-color: var(--error-color);
}

/* Header */

.site-header {
	font-family: $tertiary-type;
	font-size: 0.8em;
	position: fixed;
	right: 1em;
	text-align: right;
	top: 1em;
}

.menu-toggle, .menu-open-link, .menu-close-link {
	display: none;
}

@media #{$smaller-screen} {
	.site-header {
		background: var(--offset-color);
		border-left: 1px solid var(--accent-color-dim);
		bottom: 0;
		display: none;
		padding: 1rem 4.5rem 1rem 1rem;
		right: 0;
		top: 0;
		z-index: 1;
	}

	.menu-toggle {
		height: 0;
		left: 0;
		position: absolute;
		top: 0;
		width: 0;
	}

	.menu-toggle:checked ~ .site-header {
		display: initial;
	}

	.menu-toggle:checked ~ .content-main {
		opacity: 0.4;
	}

	.menu-toggle:checked ~ .menu-close-link {
		display: initial;
	}

	.menu-open-link, .menu-close-link {
		background: var(--offset-color);
		border: 1px solid var(--accent-color-lighter);
		color: var(--accent-color-lighter);
		cursor: pointer;
		display: block;
		font-family: $tertiary-type;
		font-size: 2rem;
		height: 2.5rem;
		line-height: 2.5rem;
		position: fixed;
		right: 1rem;
		text-align: center;
		text-decoration: none;
		top: 1rem;
		width: 2.5rem;
		z-index: 2;
	}

	.menu-close-link {
		display: none;
	}

}

.site-header h1 {
	font-family: inherit;
	font-size: 1.2em;
	font-weight: normal;
	line-height: 2.4;
	margin: 0;
	padding-bottom: 0;
	text-transform: uppercase;
}

.site-header h1 a[href] {
	background: var(--offset-color);
	border: 1px solid var(--accent-color);
	color: var(--accent-color);
}

.site-header h1 a[href]:hover {
	color: var(--offset-color);
}

.site-header a[href] {
	background: var(--secondary-color);
	color: var(--offset-color);
	display: inline-block;
	margin-bottom: 0.5em;
	padding: 0 0.7em;
	text-decoration: none;
}

.site-header a[href].external {
	background: var(--primary-color);
}

.site-header a[href]:hover {
	background: var(--accent-color);
}

.site-header .description {
	margin-top: 0;
	max-width: 13em;
}

.site-header input[type="search"] {
	background-color: var(--offset-color);
	border: 1px solid var(--secondary-color);
	line-height: 2;
	margin-bottom: 0.5em;
	padding: 0 0.7em;
	text-align: right;
}

.site-header input[type="search"]:focus {
	border-color: var(--accent-color);
	outline: none;
}

@media #{$smaller-screen} {
	.site-header input[type="search"] {
		text-align: inherit;
	}
}

/* Footer */

.site-footer {
	color: var(--secondary-color);
	text-align: center;
	-webkit-text-stroke: 1px var(--secondary-color);
	-webkit-text-fill-color: var(--offset-color);
}

/* Navigation */

.site-header nav {
	line-height: 1.6;
	text-transform: lowercase;
}

.site-header nav ul {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
}

@media #{$smaller-screen} {
	.site-header nav ul {
		text-align: inherit;
	}
}

/* Posts */

.post {
	margin-bottom: 3em;
	position: relative;
}

.post h1 {
	margin: 1rem 0;
}

.post h1 a {
	color: var(--accent-color);
	text-decoration: none;
}

.post h1 a:hover {
	background-color: initial;
	color: var(--secondary-color);
}

.post-body {
	line-height: 1.5;
	margin-top: 2rem;
}

.post-body figcaption {
	font-size: 0.8em;
	font-style: italic;
	text-align: center;
}

.post-body hr {
	width: 2em;
}

.post-body iframe {
	height: 15em;
	width: 100%;
}

.post-body ol {
	padding-left: 1.5em;
}

.post-meta {
	color: var(--primary-color-lighter);

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		vertical-align: top;

		&::after {
			content: ' /';
			opacity: 0.3;
		}

		&:last-child::after {
			content: '';
		}
	}

	.post-category::before {
		content: '#';
	}
}

.post-quote blockquote {
	border-left: 1px solid var(--accent-color);
	font-size: 2.4rem;
	font-style: normal;
	margin: 0;
	padding-left: 1rem;
}

.post-quote blockquote p {
	margin: 0;
}

.post-quote blockquote cite {
	font-size: 1rem;
}

blockquote {
	border-left: 0.5rem solid var(--secondary-color-dim);
	margin: 2rem 0;
	padding: 0 1rem;
}

/* Code blocks and inline code */
.highlight code {
	background-color: var(--secondary-color-dim);
	display: block;
	font-family: $tertiary-type;
	padding: 0.5em 1em;
}

p code {
	color: var(--primary-color-lighter);
	font-family: $tertiary-type;
}

@media #{$smaller-screen} {
	.highlight code {
		margin-left: -$smaller-screen-body-margin;
		margin-right: -$smaller-screen-body-margin;
	}
}

/* Comments */
.comments {
	font-size: 1rem;
	margin-bottom: 2em;
}

.comments ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.comments li:nth-child(odd) {
    background-color: var(--comments-stripe-color);
}

.comments li {
    padding: 1rem 0.5rem;
}

.comments li p {
	line-height: 1.3;
    margin: 0;
    padding: 0;
}

.comments .author::before {
    content: '—';
}

.comments .comment {
    margin-bottom: 1rem;
}

.comments .author, .comments time {
    font-size: 0.8rem;
    font-style: italic;
}

.comments time {
    color: hsl(0deg 0% 60%);
}

.comments h4::before {
	content: '*';
	display: block;
	text-align: center;
	margin: 1rem 0;
}

.comments form {
	font-family: $tertiary-type;
	font-size: 0.8rem;
}

.comments form p:first-of-type {
	display: none;
}

.comments textarea, .comments input {
	background: transparent;
	border: 1px solid var(--primary-color-lighter);
	box-sizing: border-box;
	color: var(--primary-color);
	margin: 0.4rem 0;
	padding: 0.2rem;
	width: 100%;
}

.comments input {
	display: block;
	line-height: 1.5;
	margin-top: 0.2rem;
}

.comments .box {
	display: none;
}

.comments .box:target {
	display: block;
}

/* Archives and Categories */
// List of years/categories
menu ul {
    margin: 1rem 0;
    padding: 0;
    list-style: none;
}

menu li {
    display: inline-block;
}

menu li::after {
	content: ' /';
	opacity: 0.1;
}

menu li:last-child::after {
    display: none;
}

// Sections show/hide
.targeted-sections > section {
	display: none;
}

.targeted-sections > section:target {
	display: inherit;
}

#all:target ~ .targeted-sections > section {
	display: inherit;
}

.posts-list {
	list-style: none;
	margin: 0 0 2em;
	padding: 0 0 0 0.5em;
}

.posts-list li {
	margin: 0.5em 0;
}

.posts-list time {
	font-size: 0.8em;
}

/* Definition Lists (Blogroll) */
dd {
    font-size: 1rem;
    margin: 0;
}

dt {
    padding-bottom: 0.5rem;
    padding-top: 2rem;
}

dt:first-of-type {
	padding-top: 0;
}
